.t-header {
  position: relative;
  width: 100%;
  height: $height-header;

  &__background {
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-size: cover !important;
    width: 100%;
    height: 600px;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-position: 50% 20% !important;
  }
  &__overlay {
    background-color: #00000070;
    width: 100%;
    height: 600px;
    position: absolute;
    top: 0;
    left: 0;
  }
  &__content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    @include extra-small-up {
      width: 80%;
    }
    @include small-up {
      width: 80%;
    }
    @include medium-up {
      width: 60%;
    }
    @include large-up {
      width: 60%;
    }
  }
  &__divider {
    background-color: $blue-light;
    height: 2px;
    opacity: 0.75;
    margin: 0 auto;
    width: 70%;
  }
  &__sub-title {
    font-size: $font-size-5;
    display: inline-block;
    margin-top: $grid-base * 1.5;
  }

  &__cta {
    margin-top: $grid-base * 2;
  }
  
}
