.o-container {
  margin-left: auto;
  margin-right: auto;
  padding-left: $gutter;
  padding-right: $gutter;
  width: 80%;

  @include extra-small-up {
    width: 95%;
    max-width: 500px;
  }
  @include small-up {
    max-width: 620px;
    width: 80%;
  }
  @include medium-up {
    max-width: 740px;
  }
  @include large-up {
    max-width: 950px;
  }
  @include xlarge-up {
    max-width: 1160px;
  }
}
