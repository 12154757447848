html {
  box-sizing: border-box;
}
html,
body {
  padding: 0px;
  margin: 0px;
}
li,
ul {
  padding: 0px;
  margin: 0px;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}
.header-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  width: 500px;
}

.navbar {
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 18px;
  width: 100%;
  position: absolute;
  top: 0;
  padding: 10px 5% 10px 5%;
  background-color: #00000020 !important;
}
.navbar--logo {
  margin-left: 20px;
  width: 50px;
  height: 50px;
}
.navbar--list__link {
  padding-right: 20px;
  color: white;
  margin-right: 20px;
  border-bottom: 2px solid transparent;
  padding-right: 0px;
  padding-bottom: 4px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}

.navbar--list__link:after {
  position: absolute;
  content: "";
  width: 0px;
  left: 0;
  bottom: 0;
  border-bottom: solid 3px #00bbff;
  transition: all 400ms ease-in-out;
  opacity: 1;
}

.navbar--list__link:hover:after {
  width: 100%;
}
.navbar--list__icon {
  color: white;

  padding-right: 6px;
  cursor: pointer;
  text-decoration: none;
  position: relative;
}
.fa-instagram,
.fa-facebook-f {
  width: 20px;
}
.icon--text {
  display: none;
}
#navbar--list {
  list-style-type: none;
  display: none;
  flex-direction: column;
  margin-right: 20px;
}

#navbar--list li {
  height: 45px;
  line-height: 45px;
}
.hamburger {
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  margin: 0;
  right: 0;
  margin-right: 20px;
}

.hamburger {
  transition: transform 0.3s 0.1s ease-in-out;
}

.hamburger__box {
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger__inner {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  position: absolute;

  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;
}

.hamburger__inner::before,
.hamburger__inner::after {
  width: 100%;
  height: 3px;
  background-color: #ffffff;
  position: absolute;

  content: "";
  left: 0;
  transition: transform 0.2s 0.2s ease-in-out;
}

.hamburger__inner::before {
  top: -10px;
}

.hamburger__inner::after {
  top: 10px;
}

.hamburger--active .hamburger__inner {
  background-color: transparent;
}

.hamburger--active .hamburger__inner:before {
  transform: translateY(10px) rotate(45deg);
}

.hamburger--active .hamburger__inner:after {
  transform: translateY(-10px) rotate(-45deg);
}

@media screen and (min-width: 920px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
  }
  #navbar--list {
    display: flex !important;
    flex-direction: row;
    justify-content: flex-end;
  }
  #navbar--list li {
    margin: 0;
  }
  .nav-links {
    margin-left: 30px;
  }
  .hamburger {
    display: none;
  }
}

@media screen and (max-width: 919px) {
  #navbar--list {
    width: 100%;
    margin-top: 30px;
    padding: 0px 0px 0px 40px;
    background-color: #1f1f1fe8 !important;
  }
  .navbar {
    flex-direction: column;
  }
  .navbar--row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .icon--text {
    margin-left: 15px;
    display: inline-block;
  }
}

@media only screen and (max-width: 290px) {
  .navbar--logo {
    margin-left: 35px;
  }
  #navbar--list {
    width: 100%;
    padding: 0px 0px 0px 25px;
  }
}
