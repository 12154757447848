p {
  font-size: $font-size-4;
  line-height: $line-height-base;
  margin-bottom: $grid-base;
  margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $font-size-4;
  line-height: $line-height-base;
  margin: 0;
}
a {
  color: $white;
  text-decoration: none;
  &:hover {
    color: $blue-text;
  }
}
