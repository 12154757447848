.t-services {
  padding-top: $grid-base * 3;
  &-content {
    padding-bottom: $grid-base * 6;
    padding-top: $grid-base * 4;
    @include extra-small-up {
      text-align: center;
    }
  }
  .services-image {
    width: 100%;
    @include extra-small-up {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @include small-up {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    @include medium-up {
      padding-top: 0px;
      padding-bottom: 0px;
    }
  }
}
