.u-white {
  color: $white;
}
.u-gray {
  color: $gray-text;
}
.u-blue {
  color: $blue-text;
}
.u-bg-light {
  background-color: $gray-light-bg;
}
.u-bg-very-dark {
  background-color: $gray-very-dark-bg;
}
.u-bg-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  opacity: 0.4;
}
