#gallery {
  min-height: 300px;
  padding-bottom: $grid-base * 4;
  padding-top: $grid-base * 2;
}
.picture-item {
  &__details {
    position: absolute;
    bottom: 0;
    transform: translateY(70px);
    background-color: #00000073;
    transition: transform 0.2s ease-out 0.2s;
  }
  &:hover &__details {
    transform: translateY(0px);
  }
}
.load-more-wrapper {
  margin-top: $grid-base * 3;
}
.play-button {
  height: 100px;
  width: 100px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.play-button-bg:hover {
  width: 95px !important;
  height: 95px !important;
}
.play-button-bg {
  cursor: pointer;
  margin-top: $grid-base * 5;
  display: block;
  height: 90px;
  width: 90px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  background-color: #00000000;
  border: none;
  outline: 0;
  opacity: 0.8;
  background-image: url("/img/btn_video_play.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.play-button-bg:hover {
  opacity: 0.8;
  background-image: url("/img/btn_video_play_hovered.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.play-button-bg:active  {
  opacity: 0.8;
  background-image: url("/img/btn_video_play_pressed.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}

.t-cta {
  background-color: $gray-light-bg;
  padding-bottom: $grid-base * 6;
  padding-top: $grid-base * 4;
  .t-cta__title {
    padding-bottom: $grid-base * 1;
    padding-top: $grid-base * 1;
  }
}

.bg-close {
  background-color: #000000bd;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
}

.youtubeVideoLoader {
  position: relative;
  cursor: pointer;
  background: #ccc no-repeat center center / cover;
  /*padding-bottom: 56.25%;  16:9 */
  height: 100%;
  clear: both;
}
#videoModalContainer {
  width: 100%;
  padding: 0px;
  top: 0;
  left: 0;
}
.modal-body {
  padding: 0px;
}
.modal-content {
  width: 100% !important;
  z-index: 999;
  @include extra-small-up {
    width: 85% !important;
  }
  @include small-up {
    width: 80% !important;
  }
  @include medium-up {
    width: 60% !important;
  }
  @include large-up {
    width: 50% !important;
  }
  @include xlarge-up {
    width: 42% !important;
  }
}
#videoModalContainer {
  @include extra-small-up {
    height: 260px !important;
  }
  @include small-up {
    height: 360px !important;
  }
}
.close-video{
  background-image: url("/img/btn_video_player_close.png");
  background-position: center; 
  background-repeat: no-repeat; 
  background-size: cover; 
  width: 36px;
  height: 36px;
  float: right;
  opacity: 0.8;
}
.close-video:hover {
  opacity: 0.8;
  background-image: url("/img/btn_video_player_close_hovered.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.close-video:active {
  opacity: 0.8;
  background-image: url("/img/btn_video_player_close_pressed.png")!important;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
/*
.close-video {
  z-index: 999;
  background-color: #ffffff00;
  width: 36px;
  height: 36px;
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.85;
  padding: 4px;
  margin-bottom: 4px;
}
.close-video:hover {
  opacity: 1;
}
.close-video:before,
.close-video:after {
  position: absolute;
  content: " ";
  height: 30px;
  width: 4px;
  background-color: #ffffff;
}
.close-video:before {
  transform: rotate(45deg);
}
.close-video:after {
  transform: rotate(-45deg);
}
*/
#myModal {
  cursor: pointer;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: #000000bd;
}

/* Modal Content/Box */
.modal-content {
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
