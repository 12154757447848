.alert {
  padding: 10px 0 10px 0;
  margin-bottom: 10px;
}
.alert-danger {
  background-color: $alert;
  width: 100%;
  color: $white;
}
.flex-item {
  width: 100%;
  max-width: 440px;
}
.login--logo {
  margin: 10px 0px 20px 0px;
  width: 70px;
  height: 70px;
}
.form-signin {
  padding: 30px 40px 30px 40px;
  border-radius: 1rem;
  background-color: #2e2e2e;
}
.form-control,
.form-group {
  width: 100% !important;
}
.footer {
  display: inline-block;
  padding: 15px;
  color: #2e2e2e;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  border: 0;
  &:focus {
    outline: none;
  }
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"],
.form-signin input[type="password"] {
  background-color: #ffffff;
  border: 2px solid $blue-light;
  outline: 0;
  margin-bottom: 10px;
}

.form-signin input[type="text"] {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.white-text {
  color: white;
}
.gray-text {
  color: gray;
}
