
@include extra-small-up {
    .btn-group .btn:first-child{
        border-radius: 0px 0 0 0px!important;
    }
    .btn{
        width: 100%!important;
        margin-top: 10px!important;
    }
  
  }
.modal-video-close-btn{
    outline: 0!important;
}
