.c-heading {
  color: $gray-heading-text;
  line-height: $line-height-sm;
  margin-bottom: $grid-base / 2;
  &--padding {
    @include extra-small-up {
      margin-bottom: $grid-base * 1;
    }
    @include medium-up {
      margin-bottom: $grid-base * 2;
    }
    @include large-up {
      margin-bottom: $grid-base * 3;
    }
  }
  &--level1 {
    font-size: $font-size-2;
    @include large-up {
      font-size: $font-size-1;
    }
  }

  &--level2 {
    font-size: $font-size-3;
    @include large-up {
      font-size: $font-size-2;
    }
  }

  &--level3 {
    font-size: $font-size-4;
    @include large-up {
      font-size: $font-size-3;
    }
  }
}
