.o-col {
  width: 100%;
  padding-left: $gutter;
  padding-right: $gutter;
  
  &--sm25 {
    @include small-up {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &--sm33 {
    @include small-up {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
  &--sm50 {
    @include small-up {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &--sm75 {
    @include small-up {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  &--md25 {
    @include medium-up {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &--md33 {
    @include medium-up {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
  &--md50 {
    @include medium-up {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &--md75 {
    @include medium-up {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
  &--lg25 {
    @include large-up {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  &--lg33 {
    @include large-up {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
  &--lg50 {
    @include large-up {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  &--lg75 {
    @include large-up {
      flex: 0 0 75%;
      max-width: 75%;
    }
  }
}
