/*
    In this layer you should have templates styles 
*/
@import '_about-us.scss';
@import '_footer.scss';
@import '_header.scss';
@import '_gallery.scss';
@import '_services.scss';
@import '_menu.scss';
@import '_contact.scss';
@import '_login.scss';
@import '_cookie.scss';