$white: #ffffff;
$black: #000000;

$alert: #cc2626;

$gray-light-bg: #f2f2f2;
$gray-very-dark-bg: rgba(0, 0, 0, 0.75);
$blue-light: #00b1ff;

$gray-heading-text: #5a5a5a; //h1
$gray-dark-text: #414141; //content text
$gray-text: #cecece; //sub-title text
$blue-text: #00b1ff;
$white-light-text: rgba(255, 255, 255, 0.75);
// Tekst
$font-size-base: 1rem;
$font-size-1: $font-size-base * 3; // ~48px
$font-size-2: $font-size-base * 2.5; // ~40px
$font-size-3: $font-size-base * 1.5; // ~24px
$font-size-4: $font-size-base * 1.125; // ~18px
$font-size-5: $font-size-base * 1; // ~16px
$font-size-6: $font-size-base * 0.875; // ~14px
$font-size-7: $font-size-base * 0.75; // ~12px
$font-size-8: $font-size-base * 0.625; // ~10px
$font-size-9: $font-size-base * 0.5625; // ~9px

$line-height-base: 1.7;
$line-height-sm: 1.2;
$line-height-lg: 1.8;

$height-header: 600px;

$grid-base: 10px;
$gutter: $grid-base * 1.5;

$bp-extra-small: "576px";
$bp-small: "576px";
$bp-medium: "768px";
$bp-large: "992px";
$bp-xlarge: "1200px";
