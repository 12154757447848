//do bardzo małych
@mixin extra-small-up {
  @media (max-width: #{$bp-extra-small}) {
    @content;
  }
}
//Od małych w góre
@mixin small-up {
  @media (min-width: #{$bp-small}) {
    @content;
  }
}
//Od średnich w góre
@mixin medium-up {
  @media (min-width: #{$bp-medium}) {
    @content;
  }
}

//Od duzych w góre
@mixin large-up {
  @media (min-width: #{$bp-large}) {
    @content;
  }
}
//Od bardzo duzych w góre
@mixin xlarge-up {
  @media (min-width: #{$bp-xlarge}) {
    @content;
  }
}
