.c-btn {
  border: none;
  display: inline-block;
  font-size: $font-size-5;
  font-weight: 700;
  line-height: $line-height-base;
  padding: 0.6rem 0;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
  width: $grid-base * 18;
  text-decoration: none;
  margin: 5px;
  &:focus {
    outline: 0;
  }
  &:hover {
    text-decoration: none;
  }
  &--primary {
    background-color: $blue-light;
    border: 2px solid $blue-light;
    color: $white;
    &:hover {
      color: darken($white, 20%);
      border: 2px solid darken($blue-light, 20%);
      background-color: darken($blue-light, 20%);
    }
  }
  &--outline-blue {
    background-color: transparent;
    border: 2px solid $blue-light;
    color: $white;
    &:hover {
      border: 2px solid $blue-light;
      background-color: $blue-light;
    }
  }

  &--outline {
    background-color: transparent;
    border: 2px solid $white;
    color: $white;

    &:hover {
      color: darken($white, 20%);
      border: 2px solid darken($white, 20%);
    }
  }
}
