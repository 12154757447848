.o-row {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: -$gutter;
  margin-right: -$gutter;

  &--height100 {
    height: 100%;
  }
  &--login {
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }

  @include medium-up {
    flex-direction: row;
  }

  &--center-y {
    align-items: center;
    -ms-flex-align: center;
  }
}
.o-flex-center-xy {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  max-width: 100%;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}
