.t-contact {
  padding-bottom: $grid-base * 6;
  padding-top: $grid-base * 2;
  &__title {
    margin-top: $grid-base * 1;
    margin-bottom: $grid-base * 3;
  }
  &__item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    @include extra-small-up {
      min-height: 170px;
    }
    @include small-up {
      min-height: 160px;
    }
  }
  &__icon {
    font-size: 30px;
    padding: 10px;
    color: $blue-light;
    border: 1px solid $blue-light;
    min-width: 50px;
    min-height: 50px;
    margin-bottom: 10px;
    display: flex!important;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }

  &__icon:hover {
    font-size: 30px;
    background-color: $blue-light;
    color: $white;
  }
}
.t-map {
  position: relative;
  width: 100%;
  height: 450px;
  .btn-map {
    top: 0;
    position: absolute;
    background-color: rgba(29, 29, 29, 0.658);
    width: 100%;
    height: 450px;
  }
  .Center-Container {
    position: relative;
    height: 100%;
  }
  #show {
    height: 50px;
    overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #exit {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.close {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 35px;
  height: 35px;
  padding: 4px;
  opacity: 0.45;
  outline: 0;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 30px;
  width: 3px;
  background-color: $blue-light;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.btn-map {
  display: block;
}
.remove {
  display: none;
}
.active {
  display: block;
}
