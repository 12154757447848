.u-width{
    &50 {
        width: 55%!important;
    }
    &70{
        width: 70%!important;
    }
    &100{
        width:100%!important;
    }
}
.u-margin{
    &--auto {
        margin: auto!important;
    }
}
video:-webkit-full-screen {
    width: 100%;
    height: 100%;
    max-height: 100%;
 }