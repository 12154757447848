.t-about-us {
  padding-bottom: $grid-base * 4;
  @include extra-small-up {
    padding-bottom: $grid-base * 2;
  }
  &-item {
    padding-bottom: $grid-base * 6;
    padding-top: $grid-base * 5;

    @include extra-small-up {
      padding-bottom: $grid-base * 3;
      padding-top: $grid-base * 3;
    }
    @include small-up {
      padding-bottom: $grid-base *3;
      padding-top: $grid-base * 3;
    }
    @include medium-up  {
      padding-bottom: $grid-base * 4;
      padding-top: $grid-base * 4;
    }
  }
  
}
